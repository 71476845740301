<template>
  <div class="d-md-flex align-items-md-center">
    <b-button
      @click="doBack()"
      variant="link"
      size="sm"
      class="ms-n2"
      v-if="hasBack"
    >
      <font-awesome-icon
        icon="chevron-left"
        class="fa-fw"
      />
    </b-button>
    <b-button
      variant="link"
      size="sm"
      class="card-header__collapse-button"
      v-if="collapsible"
      v-b-toggle="collapseId"
    >
      <font-awesome-icon
        icon="chevron-down"
        class="fa-fw"
      />
      <h5 class="mb-0">
        {{title}}
      </h5>
    </b-button>
    <h5 class="mb-0" v-else>
      <font-awesome-icon
        v-if="hasIcon"
        :icon="extraIcon"
        class="fa-fw"
      />
      {{title}}
    </h5>
    <div class="ms-md-auto mt-1 mt-md-0 card-header__extras">
      <p v-if="hasSubtitle">
        {{subtitle}}
      </p>
      <b-button
        @click="doCtaAction"
        size="sm"
        :variant="buttonVariant"
        :class="buttonClasses"
        v-if="hasButton"
      >
        {{buttonContent}}
      </b-button>
      <a
        v-if="hasExtraLink"
        :href="extraLink"
        target="_blank"
      >
        <font-awesome-icon
          icon="circle-info"
          class="text-info"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'CardHeader',
  components: {},
  props: {
    title: String,
    button: Object,
    buttonAction: Function,
    hasBack: Boolean,
    subtitle: String,
    extraIcon: String,
    collapsible: Boolean,
    collapseId: String,
    extraLink: String,
    extraLinkIcon: String,
  },
  computed: {
    hasButton() {
      return isNotEmpty(this.button);
    },
    buttonContent() {
      if (isNotEmpty(this.button?.content)) return this.button.content;
      return 'CTA';
    },
    buttonVariant() {
      if (isNotEmpty(this.button?.variant)) return this.button.variant;
      return 'warning';
    },
    buttonClasses() {
      if (isNotEmpty(this.button?.classes)) return this.button.classes;
      return '';
    },
    hasRoute() {
      return isNotEmpty(this.button?.route);
    },
    hasAction() {
      return isNotEmpty(this.buttonAction);
    },
    hasSubtitle() {
      return isNotEmpty(this.subtitle);
    },
    hasIcon() {
      return isNotEmpty(this.extraIcon);
    },
    // extraLinkComp() {
    //   return this.extraLink;
    // },
    hasExtraLink() {
      return isNotEmpty(this.extraLink);
    },
  },
  methods: {
    routeTo(path) {
      this.$router.replace(path);
    },
    doCtaAction() {
      if (this.hasRoute) this.routeTo(this.button.route);
      if (this.hasAction) this.buttonAction();
    },
    doBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>

</style>
